import config from '../config';
import { checkForRevenue, logEvent, logEventViaApi } from './amplitudeSdk';
import { getUserId } from './amplitudeUser';
import moment from 'moment';
import {
  SIGNUP_MODE,
  SIGNUP_ROLE,
  SIGNUP_USEDCODE,
  SIGNUP_CODE,
  SIGNUP_REFERRAL_CODE,
  LISTING_ID,
  LISTING_AGE,
  LISTING_APPROVAL_DATE,
  LISTING_BRAND_NAME,
  LISTING_CAN_DRIVE_TO_MALAYSIA,
  LISTING_CREATE_DATE,
  LISTING_CATEGORY,
  LISTING_COMPANY_OWNED,
  LISTING_DATE_OF_REGISTRATION,
  LISTING_ENGINE_CAPACITY,
  LISTING_FUEL_TYPE,
  LISTING_INSURANCE_TYPE,
  LISTING_IS_DRIVELAH_GO,
  LISTING_DELIVERY,
  LISTING_IS_INSTANT_BOOKING,
  LISTING_IS_PHV,
  LISTING_IS_TRACKING_DEVICE,
  LISTING_KMS_DRIVEN,
  LISTING_LICENSE_PLATE,
  LISTING_LOCATION,
  LISTING_MILEAGE_PER_DAY,
  LISTING_MODEL_NAME,
  LISTING_MONTHLY_DISCOUNT,
  LISTING_PEAK_PRICE,
  LISTING_PICS,
  LISTING_REGULAR_PRICE,
  LISTING_REVIEWS,
  LISTING_SEARCH_RESULTS,
  LISTING_STATUS,
  LISTING_SUCCESSFUL_TRIPS,
  LISTING_SUCCESSFUL_TRIPS_DAYS,
  LISTING_TITLE,
  LISTING_VEHICLE_TYPE,
  LISTING_WEEKLY_DISCOUNT,
  LISTING_REGISTRATION_NUMBER,
  LISTING_REGULARPRICE,
  LISTING_PEAKPRICE,
  LISTING_RATING,
  LISTING_SOURCE,
  LISTING_URL,
  LISTING_SEATS,
  LISTING_ATTRACTIVENESSSCORE,
  LISTING_PRICESCORE,
  LISTING_LONGTERM,
  LISTING_AVAILABILITYSCORE,
  LISTING_SUBURB,
  TListingSource,
  SEARCH_BRAND_FILTER,
  SEARCH_BRAND_VALUE,
  SEARCH_CAR_CATEGORY_FILTER,
  SEARCH_CAR_CATEGORY_VALUE,
  SEARCH_DELIVERY_FILTER,
  SEARCH_DL_GO_FILTER,
  SEARCH_DROPOFFDATE,
  SEARCH_DROPOFFTIME,
  SEARCH_FEATURES_FILTER,
  SEARCH_FEATURES_VALUE,
  SEARCH_FUEL_FILTER,
  SEARCH_FUEL_VALUE,
  SEARCH_INPUT_STRING,
  SEARCH_INSTANTBOOKING_FILTER,
  SEARCH_KEYWORD_FILTER,
  SEARCH_KEYWORD_VALUE,
  SEARCH_LOCATION,
  SEARCH_MALAYSIA_FILTER,
  SEARCH_PHV_FILTER,
  SEARCH_PICKUPDATE,
  SEARCH_PICKUPTIME,
  SEARCH_PRICE_FILTER,
  SEARCH_PRICE_VALUE,
  SEARCH_SOURCE,
  SEARCH_SUPERHOST_FILTER,
  SEARCH_TOPOFFER_FILTER,
  SEARCH_TRANSMISSION_FILTER,
  SEARCH_TRANSMISSION_VALUE,
  SEARCH_USED_SORT,
  BOOKING_DL_GO,
  BOOKING_DROP_OFF_DATE,
  BOOKING_DROP_OFF_TIME,
  BOOKING_ERROR,
  BOOKING_EXCESS2_PRICE,
  BOOKING_EXCESS_PRICE,
  BOOKING_GUEST_CREDITS_VALUE,
  BOOKING_GUEST_ID,
  BOOKING_GUEST_PROMO_VALUE,
  BOOKING_HOMEDELIVERY_PRICE,
  BOOKING_HOSTNAME,
  BOOKING_HOST_ID,
  BOOKING_INSTANT,
  BOOKING_LISTING_ID,
  BOOKING_LISTING_NAME,
  BOOKING_PICK_UP_DATE,
  BOOKING_PICK_UP_TIME,
  BOOKING_PROMO_CODE,
  BOOKING_PROMO_CREDITS,
  BOOKING_TOTAL_DISCOUNT,
  BOOKING_TOTAL_PRICE,
  BOOKING_TRIP_DAYS,
  BOOKING_TRIP_FEES,
  BOOKING_TRIP_ID,
  BOOKING_TRIP_PRICE,
  BOOKING_UNVERIFIED,
  BOOKING_VAS_DELIVERY,
  BOOKING_VAS_EXCESS,
  BOOKING_VAS_EXCESS_2,
  BOOKING_VAS_FUEL,
  BOOKING_YOUNG_DRIVER_FEES,
  LISTING_SUCCESSFULL_TRIP_DAYS,
  LISTING_POSTAL_DISTRICT,
  LISTING_SUPERHOST,
  LISTING_SUBMITTED_DATE,
  LISTING_LIVE_DATE,
  LISTING_IS_NEW,
  LISTING_HOURLY_ENABLED,
  LISTING_NEAREST_STOP,
  LISTING_PRIVATE_INSURER,
  LISTING_BAR,
  LISTING_RMS,
  LISTING_CARETAKER,
  LISTING_PICTURE_SCORE,
  UI_PROPERTY_PAGE,
  UI_PROPERTY_BUTTON_LABEL,
  TRIP_ID,
  TRIP_DLGO,
  TRIP_NOOFDAYS,
  TRIP_DROP_OFF_DATE,
  TRIP_DROP_OFF_TIME,
  TRIP_PICKUP_DATE,
  TRIP_PICKUP_TIME,
  TRIP_INSTANT_BOOKING,
  TRIP_VAS_DELIVERY,
  TRIP_VAS_EXCESS,
  TRIP_VAS_EXCESS_2,
  TRIP_VAS_FUEL,
  TRIP_GUEST_ID,
  TRIP_HOST_ID,
  TRIP_LISTING_ID,
  TRIP_LISTING_NAME,
  TRIP_PICKUP_SENT,
  TRIP_DROPOFF_SENT,
  SEARCH_USED_SORT_VALUE,
} from './analyticsConstants';
import { userDisplayNameAsString } from './data';
import { TRANSITION_CUSTOMER_PICKUP_REQUEST_LARGER_72_HOURS, TRANSITION_REQUEST_DROP_OFF, TRANSITION_REQUEST_DROP_OFF_DLGO, TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED, TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED, TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE, TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE } from './transaction';
import { getCurrentUser } from './browserStorageHelper';

export const LINE_ITEM_IS_DELIVERY = 'line-item/delivery';
export const LINE_ITEM_IS_DELIVERY_FEE = 'line-item/delivery-fee';
export const LINE_ITEM_EXCESS_REDUCTION = 'line-item/excess-reduction';
export const LINE_ITEM_EXCESS_REDUCTION2 = 'line-item/excess-reduction2';
export const LINE_ITEM_FUEL_PROTECTION = 'line-item/fuel-protection';
export const LINE_ITEM_MILEAGE_PACKAGE = 'line-item/mileage-package';
export const LINE_ITEM_CUSTOMER_PROMO = 'line-item/customer-promo';
export const LINE_ITEM_LONG_TERM_DISCOUNT = 'line-item/long-term-discount';
export const LINE_ITEM_NEW_CAR_DISCOUNT = 'line-item/new-car-discount';
export const LINE_ITEM_PROMOTION_DISCOUNT = 'line-item/promotion-discount';
export const LINE_ITEM_PROCESSING_FEE_ADDONS =
  'line-item/processing-fee-addons';
export const LINE_ITEMS_DISC = [
  LINE_ITEM_PROMOTION_DISCOUNT,
  LINE_ITEM_CUSTOMER_PROMO,
  LINE_ITEM_LONG_TERM_DISCOUNT,
  LINE_ITEM_NEW_CAR_DISCOUNT,
];

export const DISCOUNT_TYPES = {
  [LINE_ITEM_PROMOTION_DISCOUNT]: 'Promotion',
  [LINE_ITEM_CUSTOMER_PROMO]: 'Promocode',
  [LINE_ITEM_NEW_CAR_DISCOUNT]: 'New Car Discount',
  [LINE_ITEM_LONG_TERM_DISCOUNT]: 'Long Term Discount',
};

export const ALL_KINDS_OF_PICKUP = [
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_PICKUP_REQUEST_LARGER_72_HOURS,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
];

export const ALL_KINDS_OF_DROPOFF = [
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
];


export const getSpeculateAddonsPrices = (transaction) => {
  const lineItems = transaction && transaction.attributes && transaction.attributes.lineItems;
  
  const excessReduction =
    lineItems &&
    lineItems.length > 0 &&
    lineItems.find(
      item => item.code === LINE_ITEM_EXCESS_REDUCTION && !item.reversal,
    );
  
  const excessReduction2 =
    lineItems &&
    lineItems.length > 0 &&
    lineItems.find(
      item => item.code === LINE_ITEM_EXCESS_REDUCTION2 && !item.reversal,
    );
  
  const homeDeliveryFee =
    lineItems &&
    lineItems.length > 0 &&
    lineItems.find(
      item => item.code === LINE_ITEM_IS_DELIVERY_FEE && !item.reversal,
    );
  
  const mileageFee =
    lineItems &&
    lineItems.length > 0 &&
    lineItems.find(
      item => item.code === LINE_ITEM_MILEAGE_PACKAGE && !item.reversal,
    );
  
  const addonsProcessingFees =
    lineItems &&
    lineItems.length > 0 &&
    lineItems.find(
      item => item.code === LINE_ITEM_PROCESSING_FEE_ADDONS && !item.reversal,
    );

  const speculate = {
    excessReduction,
    excessReduction2,
    homeDeliveryFee,
    mileageFee,
    addonsProcessingFees,
  };

  return speculate;
};

export const getAddonsTotal = (prices) => {
  let total = 0;

  if (prices && prices.excessReduction && prices.excessReduction.lineTotal) {
    total = total + prices.excessReduction.lineTotal.amount;
  }
  if (prices && prices.excessReduction2 && prices.excessReduction2.lineTotal) {
    total = total + prices.excessReduction2.lineTotal.amount;
  }
  if (prices && prices.homeDeliveryFee && prices.homeDeliveryFee.lineTotal) {
    total = total + prices.homeDeliveryFee.lineTotal.amount;
  }
  if (prices && prices.milageFee && prices.milageFee.lineTotal) {
    total = total + prices.milageFee.lineTotal.amount;
  }
  if (prices && prices.addonsProcessingFees && prices.addonsProcessingFees.lineTotal) {
    total = total + prices.addonsProcessingFees.lineTotal.amount;
  }

  return total;
};


export const ROLE = {
  HOST: 'host',
  RENTER: 'renter',
  GUEST: 'guest',
  PROVIDER: 'provider',
  CUSTOMER: 'customer',
};

export const createEventProperties = (bucket, eventData, event) => {
  let properties = {};
  let correspondingProperties = bucket ? bucket.eventProperties.filter(
    property => event && event[property.Bucket] === 'x',
  ) : [];

  if (correspondingProperties && correspondingProperties.length > 0) {
    correspondingProperties.forEach(property => {
      let propName = formatName(property.Property_Name.replace(/ /g, '_'));
      properties = {
        ...properties,
        [propName]: eventData[propName] || '',
      };
    });
  }
  return properties;
};


export const formatName = (str) => {
  return str.replace(/\s+/g, '_');
};

export const determineEvent = (bucket, eventId, eventData) => {
  let event = bucket && bucket.eventDetails.find(
    event => event.event_trigger_id === eventId || event.Event_Name === eventId,
  );
  let {
      ui,
      signup,
      guest,
      search,
      host,
      enquiry,
      booking,
      vas,
      listing,
      trip,
      currentUser
    } = eventData;
  let amplitudeProps = {};

  let uiProps = createUIAndDateTimeProperties(
    ui ? ui.page : '',
    ui ? ui.button : '',
  );
  let signupProps = signup ? createSignupProperties(signup) : {};
  let guestProps = guest ? createGuestProperties(guest) : {};
  let searchProps = search ? createSearchProperties(search, ui ? ui.page : '') : {};
  let hostProps = host ? createHostProperties(host) : {};
  let listingProps = listing ? createListingProperties(listing, ui ? ui.page : '') : {};
  let enquiryProps = enquiry ? createBookingProperties(enquiry) : {};
  let bookingProps = booking ? createBookingProperties(booking) : {};
  if (booking) {
    bookingProps['booking_returning_user'] = guestProps['guest_isreturning'];
  }
  let tripProps = trip ? createTripProperties(trip) : {};
  let vasProps = vas ? createVASProperties(vas, ui ? ui.page : '') : {};
  amplitudeProps = {
    ...amplitudeProps,
    ...uiProps,
    ...signupProps,
    ...guestProps,
    ...searchProps,
    ...hostProps,
    ...enquiryProps,
    ...bookingProps,
    ...vasProps,
    ...listingProps,
    ...tripProps,
  };
  let properties = {};
  if (event) properties = createEventProperties(bucket, amplitudeProps, event);
  return {
    event,
    properties,
  };
};



// export const sendEvent = (bucket, eventId, eventData) => {
//   let data = determineEvent(bucket, eventId, eventData);
//   if (data.event) {
//     amplitude.track(data.event.Event_Name, data.properties);
//     // moEngage call
//     // trackBrowsingMoEngage(event, ui, data);
//   }
// };

export const createSignupProperties = (data) => {
  return {
    [SIGNUP_MODE]: data.mode || '',
    [SIGNUP_ROLE]: data.role || '',
    [SIGNUP_USEDCODE]: data.codeType || '',
    [SIGNUP_CODE]: data.code || '',
    [SIGNUP_REFERRAL_CODE]: data.referral || '',
  };
};

// export const userLoginToAmplitude = (currentUser) => {
//   if (currentUser && currentUser.id) {
//     let properties = createUserProperties(currentUser);
//     amplitude.setUserId(currentUser.id.uuid);
//     if (properties && properties.data) {
//       let identity = new amplitude.Identify();
//       Object.keys(properties.data).forEach(key => {
//         identity.set(key, properties.data[key]);
//       });
//       amplitude.identify(identity);
//     }
//   }
// };

// export const logoutFromAmplitude = () => {
//   amplitude.reset();
// };

export const createUIAndDateTimeProperties = (page, button) => {
  const dateTimeProperties = computeDateTime(new Date());
  
  return {
    [UI_PROPERTY_PAGE]: page || '',
    [UI_PROPERTY_BUTTON_LABEL]: button || '',
    ...dateTimeProperties,
  };
};

export const createUserProperties = (currentUser, prefix = 'User') => {
  try {
    if (!currentUser || !currentUser.id) {
      return;
    }

    const data = {};
    const attributes = currentUser.attributes || {};
    const profile = attributes.profile || {};
    const emailVerified = attributes.emailVerified || {};
    const metadata = profile.metadata || {};
    const protectedData = profile.protectedData || {};
    const publicData = profile.publicData || {};
    const intercomUserStat = metadata.intercomUserStat || {};
    const location = protectedData.location || {};
    const selectedPlace = location.selectedPlace || {};
    const locationProxy = protectedData.locationProxy || {};
    const selectedPlaceProxy = locationProxy.selectedPlace || {};

    const { firstName, lastName } = profile;
    // const computeDateTime = date => new Date(date).toISOString(); // assuming computeDateTime is a function that formats dates

    data['email'] = attributes.email;
    data['user_firstname'] = firstName;
    data['user_lastname'] = lastName;
    data['user_fullname'] = firstName && lastName ? `${firstName} ${lastName}` : userDisplayNameAsString(currentUser);
    data['user_stripeID'] = (currentUser.stripeAccount || {}).attributes.stripeAccountId;
    data['user_flexID'] = currentUser.id.uuid;
    data['user_hoststatus'] = publicData.hostIdentityVerificationStatus;
    data['user_gueststatus'] = publicData.guestIdentityVerificationStatus;
    data['user_age'] = protectedData.age || intercomUserStat.age;
    data['user_address'] = selectedPlace.address;
    data['user_postalcode'] = protectedData.postalCode;
    data['user_postalcode_proxy'] = selectedPlaceProxy.postalCode;
    data['user_postal_district'] = protectedData.postalDistrict;
    data['user_city'] = protectedData.city;
    data['user_country'] = protectedData.country;
    data['user_dateofbirth'] = protectedData.dob || intercomUserStat.date_of_birth;
    data['user_photo'] = !!currentUser.profileImage && !!currentUser.profileImage.id;
    data['phone'] = protectedData.phoneNumber;
    data['user_phoneverified'] = protectedData.phoneNumberVerified || intercomUserStat.phone_number_verified;
    data['user_bookingacceptancerate'] = intercomUserStat.booking_acceptance_rate;
    data['user_bookingacceptancerate_l90'] = metadata.booking_acceptance_rate_last_three_months;
    data['user_responserate'] = intercomUserStat.booking_response_rate;
    data['user_signedupdate'] = computeDateTime(attributes.createdAt);
    data['user_approvaldatehost'] = computeDateTime(metadata.hostVerifiedDate);
    data['user_approvaldateguest'] = computeDateTime(metadata.guestVerifiedDate);
    data['user_status'] = !attributes.banned && !attributes.deleted ? 'opened' : 'banned';
    data['user_UTM_source'] = 'N/A';
    data['user_UTM_medium'] = 'N/A';
    data['user_UTM_campaign'] = 'N/A';
    data['user_UTM_content'] = 'N/A';
    data['user_role'] = publicData.role;
    data['user_successful_trips_guest'] = intercomUserStat.success_booking_as_guest;
    data['user_successful_trips_host'] = intercomUserStat.number_of_trips_success_as_host;
    data['user_successful_trips_host_days'] = intercomUserStat.number_of_trips_day_completed_as_host;
    data['user_successful_trips_host_l90'] = metadata.number_of_trips_completed_as_host_in_last_three_months;
    data['user_responsetime'] = intercomUserStat.average_booking_responded_time_as_host;
    data['user_bookingsaccepted_as_host'] = metadata.number_of_booking_requests_accepted_as_host;
    data['user_stripe_account_status'] = metadata.stripeConnectedAccountData.accountStatus;
    data['user_bookingaccepted_as_guest'] = metadata.number_of_booking_requests_accepted_as_guest;
    data['user_bookingsdeclined_as_host'] = intercomUserStat.number_of_booking_requests_declined_as_host;
    data['user_tripguestcancel_guest'] = intercomUserStat.number_of_trips_cancelled_by_customer_as_guest;
    data['user_tripguestcancel_host'] = intercomUserStat.number_of_trips_cancelled_by_customer_as_host;
    data['user_enquiriesreceived'] = intercomUserStat.number_of_enquiries_received;
    data['user_enquiriessent'] = intercomUserStat.enquiries_sent;
    data['user_triphostcancel_host'] = metadata.number_of_trips_cancelled_by_provider_as_host;
    data['user_tripguestcancel_guest'] = metadata.number_of_trips_cancelled_by_customer_as_guest;
    data['user_tripguestcancel_host'] = metadata.number_of_trips_cancelled_by_customer_as_host;
    data['user_bookingfailed_guest'] = intercomUserStat.number_of_booking_requests_payment_failed_as_guest;
    data['user_first_listing_date'] = computeDateTime(metadata.first_listing_creation_date);
    data['user_bookings_responded_host'] = metadata.number_of_booking_requests_accepted_as_host;
    data['user_district'] = protectedData.postalDistrict;
    data['user_bookingsent_guest'] = intercomUserStat.booking_requests_sent;
    data['user_bookingrecd_host'] = intercomUserStat.booking_requests_received;
    data['user_email_verified'] = emailVerified;
    data['user_published_listings'] = intercomUserStat.number_of_published_listings;
    data['user_live_listings'] = intercomUserStat.number_of_live_listings;
    data['user_pending_listings'] = intercomUserStat.number_of_pending_listings;
    data['user_draft_listings'] = intercomUserStat.number_of_draft_listings;
    data['user_ID'] = currentUser.id.uuid;
    data['user_referral_link'] = intercomUserStat.referral_link;
    data['user_referralcodeused'] = protectedData.referralCode;
    data['user_uniquereferralcode'] = metadata.referralCode;
    data['user_reffered_booked_trip'] = 'N/A';
    data['user_reffered_hosted_trip'] = 'N/A';
    data['user_credits'] = 'N/A';
    data['user_averagerating'] = 'N/A';
    data['user_bookings_not_responded_host'] = intercomUserStat.number_of_booking_requests_not_responded_as_host;
    data['user_suburb'] = 'N/A';
    data['user_average_picture_score'] = publicData.averagePictureScore;
    data['user_average_price_score'] = publicData.averagePriceScore;
    data['user_from_iOS'] = profile.privateData.fromMobile;
    data['user_from_android'] = profile.privateData.fromAndroid;
    data['user_host_segment'] = publicData.hostSegment;
    data['user_last_search'] = publicData.lastSearchAt;
    data['user_last_booking_guest'] = intercomUserStat.last_success_booking_as_guest_at;
    data['user_last_booking_host'] = intercomUserStat.last_success_booking_as_host_at;
    data['user_booking_response_time_host'] = intercomUserStat.average_booking_responded_time_as_host;
    data['user_signup_method'] = protectedData.signupMethod;
    data['user_singpass'] = protectedData.singpassConfirmed;
    data['user_miles_club'] = publicData.isPremiumUser;
    data['user_miles_club_tier'] = publicData.premiumUserTier;
    data['user_superhost'] = intercomUserStat.superhost;

    return {
      data: data,
      userId: currentUser.id.uuid,
    };
  } catch (error) {
    return {};
  }
};


// export const createUserProperties = (currentUser, prefix = 'User') => {
//   try {
//     if (!currentUser || !currentUser.id) {
//       return;
//     }
//     const data = {};
//     const { profile, createdAt, banned, deleted, emailVerified } = currentUser.attributes || {};
//     const { firstName, lastName } = profile || {};
//     data['user_firstname'] = firstName;
//     data['user_lastname'] = lastName;
//     data['user_fullname'] = firstName && lastName ? `${firstName} ${lastName}` : userDisplayNameAsString(currentUser);
//     data['user_stripeID'] = currentUser.stripeAccount && currentUser.stripeAccount.attributes && currentUser.stripeAccount.attributes.stripeAccountId;
//     data['user_flexID'] = currentUser.id && currentUser.id.uuid;
//     data['user_hoststatus'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.publicData && currentUser.attributes.profile.publicData.hostIdentityVerificationStatus;
//     data['user_gueststatus'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.publicData && currentUser.attributes.profile.publicData.guestIdentityVerificationStatus;
//     data['user_age'] = (currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.age) || (currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.age);
//     data['user_address'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.location && currentUser.attributes.profile.protectedData.location.selectedPlace && currentUser.attributes.profile.protectedData.location.selectedPlace.address;
//     data['user_postalcode'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.postalCode;
//     data['user_city'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.city;
//     data['user_country'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.country;
//     data['user_dateofbirth'] = (currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.dob) || (currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.date_of_birth);
//     data['user_photo'] = currentUser.profileImage && currentUser.profileImage.id;
//     data['user_phone'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.phoneNumber;
//     data['user_phoneverified'] = (currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData && currentUser.attributes.profile.protectedData.phoneNumberVerified) || (currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.phone_number_verified);
//     data['user_bookingacceptancerate'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.booking_acceptance_rate;
//     data['user_responserate'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.booking_response_rate;
//     data['user_signedupdate'] = new Date(createdAt).toString();
//     data['user_approvaldatehost'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.hostVerifiedDate;
//     data['user_approvaldateguest'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.guestVerifiedDate;
//     data['user_status'] = !banned && !deleted ? 'opened' : 'banned';
//     data['user_successful_trips_guest'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.success_booking_as_guest;
//     data['user_successful_trips_host'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_trips_success_as_host;
//     data['user_successful_trips_host_days'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_trips_day_completed_as_host;
//     data['user_responsetime'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.average_booking_responded_time_as_host;
//     data['user_tripguestcancel_guest'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_trips_cancelled_by_customer_as_guest;
//     data['user_tripguestcancel_host'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_trips_cancelled_by_customer_as_host;
//     data['user_bookingsdeclined_as_host'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_booking_requests_declined_as_host;
//     data['user_bookingfailed_guest'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_booking_requests_payment_failed_as_guest;
//     data['user_bookingrecd_host'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.booking_requests_received;
//     data['user_bookingsent_guest'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.booking_requests_sent;
//     data['user_email_verified'] = emailVerified;
//     data['user_published_listings'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_published_listings;
//     data['user_live_listings'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_live_listings;
//     data['user_pending_listings'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_pending_listings;
//     data['user_draft_listings'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_draft_listings;
//     data['user_enquiriesreceived'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.number_of_enquiries_received;
//     data['user_enquiriessent'] = currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.intercomUserStat && currentUser.attributes.profile.metadata.intercomUserStat.enquiries_sent;
//     data['user_email'] = currentUser.attributes && currentUser.attributes.email;
//     data['email'] = currentUser.attributes && currentUser.attributes.email;
//     data['user_postalcode_proxy'] = profile && profile.protectedData && profile.protectedData.locationProxy && profile.protectedData.locationProxy.selectedPlace && profile.protectedData.locationProxy.selectedPlace.postalCode;
//     data['user_postal_district'] = profile && profile.protectedData && profile.protectedData.postalDistrict;
//     data['user_superhost'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.superhost;
//     data['user_bookingacceptancerate_l90'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.booking_acceptance_rate_last_three_months;
//     data['user_UTM_source'] = 'N/A';
//     data['user_UTM_medium'] = 'N/A';
//     data['user_UTM_campaign'] = 'N/A';
//     data['user_UTM_content'] = 'N/A';
//     data['user_role'] = profile && profile.publicData && profile.publicData.role;
//     data['user_successful_trips_host_l90'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.number_of_trips_completed_as_host_in_last_three_months;
//     data['user_bookingsaccepted_as_host'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.number_of_booking_requests_accepted_as_host;
//     data['user_stripe_account_status'] = profile && profile.metadata && profile.metadata.stripeConnectedAccountData && profile.metadata.stripeConnectedAccountData.accountStatus;
//     data['user_bookingaccepted_as_guest'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.number_of_booking_requests_accepted_as_guest;
//     data['user_triphostcancel_host'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.number_of_trips_cancelled_by_provider_as_host;
//     data['user_first_listing_date'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.first_listing_creation_date;
//     data['user_bookings_responded_host'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.number_of_booking_requests_accepted_as_host;
//     data['user_district'] = profile && profile.protectedData && profile.protectedData.postalDistrict;
//     data['user_ID'] = currentUser.id && currentUser.id.uuid;
//     data['user_referral_link'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.referral_link;
//     data['user_referralcodeused'] = profile && profile.protectedData && profile.protectedData.referralCode;
//     data['user_uniquereferralcode'] = profile && profile.metadata && profile.metadata.referralCode;
//     data['user_reffered_booked_trip'] = 'N/A';
//     data['user_reffered_hosted_trip'] = 'N/A';
//     data['user_credits'] = 'N/A';
//     data['user_averagerating'] = 'N/A';
//     data['user_bookings_not_responded_host'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.number_of_booking_requests_not_responded_as_host;
//     data['user_suburb'] = 'N/A';
//     data['user_average_picture_score'] = profile && profile.publicData && profile.publicData.averagePictureScore;
//     data['user_average_price_score'] = profile && profile.publicData && profile.publicData.averagePriceScore;
//     data['user_from_iOS'] = profile && profile.privateData && profile.privateData.fromMobile;
//     data['user_from_android'] = profile && profile.privateData && profile.privateData.fromAndroid;
//     data['user_host_segment'] = profile && profile.publicData && profile.publicData.hostSegment;
//     data['user_last_search'] = profile && profile.publicData && profile.publicData.lastSearchAt;
//     data['user_last_booking_guest'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.last_success_booking_as_guest_at;
//     data['user_last_booking_host'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.last_success_booking_as_host_at;
//     data['user_booking_response_time_host'] = profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.average_booking_responded_time_as_host;
//     data['user_signup_method'] = profile && profile.protectedData && profile.protectedData.signupMethod;
//     data['user_singpass'] = profile && profile.protectedData && profile.protectedData.singpassConfirmed;
//     data['user_miles_club'] = profile && profile.publicData && profile.publicData.isPremiumUser;
//     data['user_miles_club_tier'] = profile && profile.publicData && profile.publicData.premiumUserTier;

//     return {
//       data: data,
//       userId: currentUser.id.uuid
//     };
//   } catch (error) {
//     return {};
//   }
// };


export const createGuestProperties = (user) => {
  if (user) {
    const { profile } = user.attributes || {};

    let result = {
      guest_address: (profile && profile.protectedData && profile.protectedData.location && profile.protectedData.location.selectedPlace && profile.protectedData.location.selectedPlace.address) || '',
      guest_email: (user.attributes && user.attributes.email) || '',
      guest_flexID: (user.id && user.id.uuid) || '',
      guest_gueststatus: profile && profile.publicData && profile.publicData.guestIdentityVerificationStatus,
      guest_isreturning: (profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.success_booking_as_guest) > 0,
      guest_miles_club: (profile && profile.publicData && profile.publicData.isPremiumUser) || false,
      guest_name: `${profile && profile.firstName} ${profile && profile.lastName}`,
      guest_phone: profile && profile.protectedData && profile.protectedData.phoneNumber,
      guest_postalcode: profile && profile.protectedData && profile.protectedData.postalCode,
      guest_postal_district: profile && profile.protectedData && profile.protectedData.postalDistrict,
    };
    return result;
  }
  return {};
};

export const createHostProperties = (user) => {
  if (user) {
    const { profile } = user.attributes || {};

    let result = {
      host_address: (profile && profile.protectedData && profile.protectedData.location && profile.protectedData.location.selectedPlace && profile.protectedData.location.selectedPlace.address) || '',
      host_email: (user.attributes && user.attributes.email) || '',
      host_flexID: (user.id && user.id.uuid) || '',
      host_superhost: (profile && profile.publicData && profile.publicData.superHost) || false,
      host_bar: profile && profile.metadata && profile.metadata.intercomUserStat && profile.metadata.intercomUserStat.booking_acceptance_rate,
      host_name: profile && profile.firstName
        ? `${profile.firstName} ${profile.lastName}`
        : (profile && profile.displayName),
      host_phone: profile && profile.protectedData && profile.protectedData.phoneNumber,
      // host_postalcode: profile?.protectedData?.postalCode, // S
      host_hoststatus: profile && profile.publicData && profile.publicData.hostIdentityVerificationStatus,
      host_segment: profile && profile.publicData && profile.publicData.hostSegment,
      host_postaldistrict: profile && profile.protectedData && profile.protectedData.postalDistrict,
    };
    return result;
  }
  return {};
};

export const createListingProperties = (listing, source) => {
  if (!listing || !listing.id) {
    return {};
  }

  const { attributes } = listing;
  const { publicData, metadata, privateData } = attributes || {};

  if (!publicData) {
    return {};
  }

  const { pricing = {} } = publicData || {};
  const data = {};

  data[LISTING_ID] = listing.id.uuid;
  data[LISTING_AGE] = publicData.ageOfCar;
  data[LISTING_APPROVAL_DATE] = computeDateTime(metadata && metadata.listingApprovedAt).date;
  data[LISTING_BRAND_NAME] = publicData.brandName;
  data[LISTING_CAN_DRIVE_TO_MALAYSIA] = publicData.canDriveToMalaysia;
  data[LISTING_CREATE_DATE] = computeDateTime(attributes.createdAt).date;
  data[LISTING_CATEGORY] = publicData.category;
  data[LISTING_COMPANY_OWNED] = publicData.companyOwned ? publicData.companyOwned : 'no';
  data[LISTING_DATE_OF_REGISTRATION] = publicData.dateOfRegistration;
  data[LISTING_ENGINE_CAPACITY] = publicData.engineCapacity;
  data[LISTING_FUEL_TYPE] = publicData.fuelType;
  data[LISTING_INSURANCE_TYPE] = publicData.insurance;
  data[LISTING_IS_DRIVELAH_GO] = !!(metadata && metadata.isDrivelahGo);
  data[LISTING_DELIVERY] = !!publicData.delivery;
  data[LISTING_IS_INSTANT_BOOKING] = !!publicData.instantBooking;
  data[LISTING_IS_PHV] = !!publicData.isPHV;
  data[LISTING_IS_TRACKING_DEVICE] =
    privateData &&
    privateData.trackingDeviceId &&
    privateData.trackingDeviceId !== 'ADMIN FILL INFORMATION HERE'
      ? true
      : false;
  data[LISTING_KMS_DRIVEN] = publicData.drivenKm;
  data[LISTING_LICENSE_PLATE] = publicData.license_plate_number;
  data[LISTING_LOCATION] =
    (publicData.location && publicData.location.address) || null;
  data[LISTING_MILEAGE_PER_DAY] = publicData.millagePerDay;
  data[LISTING_MODEL_NAME] = publicData.modelName;
  data[LISTING_MONTHLY_DISCOUNT] = pricing.discount && pricing.discount.month;
  data[LISTING_PEAK_PRICE] = pricing.peakPrice && pricing.peakPrice.amount;
  data[LISTING_PICS] = listing.images && listing.images.length > 0 ? 'yes' : 'no';
  data[LISTING_REVIEWS] = metadata && metadata.reviews && metadata.reviews.averageRating;
  data[LISTING_SEARCH_RESULTS] = publicData.searchResults;
  data[LISTING_STATUS] = attributes.state;
  data[LISTING_SUCCESSFUL_TRIPS] = publicData.numberTripDone;
  data[LISTING_SUCCESSFUL_TRIPS_DAYS] = null;
  data[LISTING_TITLE] = attributes.title;
  data[LISTING_VEHICLE_TYPE] = publicData.vehicleType;
  data[LISTING_WEEKLY_DISCOUNT] = pricing.discount && pricing.discount.week;
  data[LISTING_REGISTRATION_NUMBER] = publicData.license_plate_number;
  data[LISTING_REGULARPRICE] = pricing;
  data[LISTING_PEAKPRICE] = pricing.peakPrice;
  data[LISTING_RATING] = metadata && metadata.reviews && metadata.reviews.averageRating;
  data[LISTING_SOURCE] = source || null;
  data[LISTING_URL] = config.canonicalRootURL + listing.id.uuid;
  data[LISTING_SEATS] = publicData.peopleNumberMax;
  data[LISTING_ATTRACTIVENESSSCORE] = publicData.attractiveScore;
  data[LISTING_PRICESCORE] = publicData.priceScore;
  data[LISTING_LONGTERM] = pricing.longTermPriceAmount;
  data[LISTING_AVAILABILITYSCORE] = publicData.availabilityScore;
  data[LISTING_SUBURB] = publicData.location && publicData.location.neighborhood;
  data[LISTING_SUCCESSFULL_TRIP_DAYS] = publicData.location && publicData.location.neighborhood;
  data[LISTING_POSTAL_DISTRICT] = publicData.location && publicData.location.neighborhood;
  data[LISTING_SUPERHOST] = publicData.isSuperHost;
  data[LISTING_SUBMITTED_DATE] = publicData.location && publicData.location.neighborhood;
  data[LISTING_LIVE_DATE] = attributes.metadata && attributes.metadata.liveAt;
  data[LISTING_IS_NEW] = attributes.metadata && attributes.metadata.isNewCar;
  data[LISTING_HOURLY_ENABLED] = publicData.hourlyBooking;
  data[LISTING_NEAREST_STOP] = publicData.location && publicData.location.nearestStop;
  data[LISTING_PRIVATE_INSURER] = publicData.privateInsurer;
  data[LISTING_BAR] = publicData.booking_acceptance_rate;
  data[LISTING_RMS] = publicData.isRMS;
  data[LISTING_CARETAKER] = publicData.isCaretaker;
  data[LISTING_PICTURE_SCORE] = publicData.pictureScore;
  data['listing_views'] = publicData.views;

  return data;
};

export const createVASProperties = (tx, source) => {
  const data = {};
  if (tx.id) {
    const { attributes } = tx;
    const { protectedData, lineItems } = attributes || {};

    data['vas_delivery'] = protectedData && protectedData.isDelivery;
    data['vas_excess'] = protectedData && protectedData.isExcessReduction;
    data['vas_excess_2'] = protectedData && protectedData.isExcessReduction2;
    data['vas_fuel'] = protectedData && protectedData.isFuelInclusion;
    data['vas_mileage'] = protectedData && protectedData.isMileagePackageIncluded;
    if (protectedData && protectedData.isMileagePackageIncluded) {
      data['vas_mileage_selected_mileage'] = protectedData.selectedMileage;
    }

    lineItems.forEach((item) => {
      switch (item.code) {
        case LINE_ITEM_EXCESS_REDUCTION2:
          data['vas_excess_2_price'] = computePrice(item.lineTotal && item.lineTotal.amount);
          break;
        case LINE_ITEM_EXCESS_REDUCTION:
          data['vas_excess_price'] = computePrice(item.lineTotal && item.lineTotal.amount);
          break;
        case LINE_ITEM_FUEL_PROTECTION:
          if (item.includeFor.includes(ROLE.CUSTOMER)) {
            data['vas_fuel_price'] = computePrice(item.lineTotal && item.lineTotal.amount);
          } else if (item.includeFor.includes(ROLE.PROVIDER)) {
            data['vas_fuel_earnings'] = computePrice(item.lineTotal && item.lineTotal.amount);
          }
          break;
        case LINE_ITEM_MILEAGE_PACKAGE:
          if (item.includeFor.includes(ROLE.CUSTOMER)) {
            data['vas_mileage_price'] = computePrice(item.lineTotal && item.lineTotal.amount);
          } else if (item.includeFor.includes(ROLE.PROVIDER)) {
            data['vas_mileage_earnings'] = computePrice(item.lineTotal && item.lineTotal.amount);
          }
          break;
        case LINE_ITEM_IS_DELIVERY:
          if (item.includeFor.includes(ROLE.CUSTOMER)) {
            data['vas_delivery_price'] = computePrice(item.lineTotal && item.lineTotal.amount);
          } else if (item.includeFor.includes(ROLE.PROVIDER)) {
            data['vas_deliver_earnings'] = computePrice(item.lineTotal && item.lineTotal.amount);
          }
          break;
      }
    });
  } else {
    data['vas_delivery'] = tx && tx.isDelivery ;
    data['vas_excess'] = (tx && tx.insurance === 'premiumProtection' ? true : false) || tx.isExcessReduction;
    data['vas_excess_2'] = (tx && tx.insurance === 'maximumProtection' ? true : false) || tx.isExcessReduction2; 
    data['vas_fuel'] = tx && tx.isFuelInclusion;
    data['vas_mileage'] = tx && tx.isMileagePackageIncluded;
  }

  data['vas_source'] = source;
  return data;
};


export const createSearchProperties = ({searchParams, source, bookingTx = null}) => {
  // console.log("Search params for event", searchParams);
  const data = {};
  if (!searchParams) {
    return {};
  }
  data[SEARCH_LOCATION] = searchParams && searchParams.address;
  data['search_results_returned'] = searchParams && searchParams.search_results_returned;
  let startDate = searchParams.bookingStartDate;
  let endDate = searchParams.bookingEndDate;
  if(searchParams.start) {
    if (searchParams && searchParams.start) startDate = searchParams.start;
    else startDate = searchParams && searchParams.dates.start;
  }

  if(searchParams.end) {
    if (searchParams && searchParams.end) endDate = searchParams.end;
    else endDate = searchParams && searchParams.dates.end;
  }

  let tempStart, tempEnd;
  if(searchParams.bookingStartDate) {
    tempStart = computeDateTime(startDate.date);
    // console.log("Taking bookg time start", startDate.date, tempStart)
  }
  else {
    tempStart = computeDateTime(startDate);
  }

 

  data[SEARCH_PICKUPDATE] = tempStart && tempStart.date;
  data[SEARCH_PICKUPTIME] = tempStart && tempStart.time;

  if(searchParams.bookingEndDate) {
    tempEnd = computeDateTime(endDate.date);
    // console.log("Taking bookg time start", endDate.date, tempEnd)
  }
  else {
    tempEnd = computeDateTime(endDate);
  }

  data[SEARCH_DROPOFFDATE] = tempEnd && tempEnd.date;
  data[SEARCH_DROPOFFTIME] = tempEnd && tempEnd.time;

  // Convert timestamps to moments and log them
  let endTimeMoment, startTimeMoment;
  if(!searchParams.bookingEndDate) {
    endTimeMoment = moment(endDate)
  }
  else {
    endTimeMoment = moment(endDate.date);
  }

  if(!searchParams.bookingStartDate) {
    startTimeMoment = moment(startDate)
  }
  else {
    startTimeMoment = moment(startDate.date);
  }

  if(bookingTx) {
    // console.log("Bookign came", bookingTx)
    const { booking } = bookingTx;
    const { start, end, displayStart, displayEnd } = booking.attributes;
    data[SEARCH_PICKUPDATE] = computeDateTime(start).date;
    data[SEARCH_PICKUPTIME] = computeDateTime(displayStart).time;
    data[SEARCH_DROPOFFDATE] = computeDateTime(end).date;
    data[SEARCH_DROPOFFTIME] = computeDateTime(displayEnd).time;
    startTimeMoment = moment(displayStart);
    endTimeMoment = moment(displayEnd);
    // console.log("Bookgin data", booking, data);
  }
  
  // console.log("EndTime moment:", endTimeMoment.format(), "StartTime moment:", startTimeMoment.format());
  
  // Calculate difference in hours
  let difference = endTimeMoment.diff(startTimeMoment, 'hours');
  let differenceDay = endTimeMoment.diff(startTimeMoment, 'day');
  // console.log("Difference in hours:", difference);
  data['search_type'] = difference < 12 ? 'hourly' : 'daily';
  data['search_duration'] = `${difference}`;
  data['search_duration'] =
  difference >= 12 && difference < 24
    ? '0.5'
    : `${differenceDay}`;

  data[SEARCH_CAR_CATEGORY_FILTER] = searchParams && searchParams.pub_category ? true : false;
  data[SEARCH_CAR_CATEGORY_VALUE] = searchParams && searchParams.pub_category;
  data[SEARCH_PRICE_FILTER] = searchParams && searchParams.price ? true : false;
  data[SEARCH_PRICE_VALUE] = searchParams && searchParams.price;
  data[SEARCH_KEYWORD_FILTER] = searchParams && searchParams.keywords ? true : false;
  data[SEARCH_KEYWORD_VALUE] = searchParams && searchParams.keywords;
  data[SEARCH_DL_GO_FILTER] = searchParams && searchParams.meta_isDrivelahGo;
  data[SEARCH_BRAND_FILTER] = searchParams && searchParams.pub_brandName ? true : false;
  data[SEARCH_BRAND_VALUE] = searchParams && searchParams.pub_brandName;
  data[SEARCH_FEATURES_FILTER] = searchParams && searchParams.pub_keyFeatures ? true : false;
  data[SEARCH_FEATURES_VALUE] = searchParams && searchParams.pub_keyFeatures;
  data[SEARCH_TRANSMISSION_FILTER] = searchParams && searchParams.pub_transmissions
    ? true
    : false;
  data[SEARCH_TRANSMISSION_VALUE] = searchParams && searchParams.pub_transmissions;
  data[SEARCH_PHV_FILTER] = searchParams && searchParams.pub_isPHV;
  data[SEARCH_MALAYSIA_FILTER] = searchParams && searchParams.pub_canDriveToMalaysia;
  data[SEARCH_FUEL_FILTER] = searchParams && searchParams.pub_fuelType ? true : false;
  data[SEARCH_FUEL_VALUE] = searchParams && searchParams.pub_fuelType;

  data[SEARCH_USED_SORT] = searchParams && searchParams.sort ? true : false;
  data[SEARCH_USED_SORT_VALUE] = searchParams && searchParams.sort;
  data[SEARCH_DELIVERY_FILTER] = searchParams && searchParams.pub_delivery;
  data[SEARCH_SOURCE] = source;
  data[SEARCH_INPUT_STRING] = searchParams && searchParams.address;
  data[SEARCH_INSTANTBOOKING_FILTER] = searchParams && searchParams.pub_instantBooking;
  data[SEARCH_SUPERHOST_FILTER] = searchParams && searchParams.pub_isSuperHost;
  data[SEARCH_TOPOFFER_FILTER] = searchParams && searchParams.pub_isTopOffer;
  data['search_error_on_search'] = searchParams && searchParams.errorInSearch;
  data['search_error_on_listing'] = searchParams && searchParams.errorInListing;
  data['search_filter_used'] = [];

  if (
    searchParams.pub_canDriveToMalaysia ||
    searchParams.pub_delivery ||
    searchParams.pub_isTopOffer ||
    searchParams.pub_isPHV ||
    searchParams.pub_isSuperHost ||
    searchParams.pub_isPetFriendly ||
    searchParams.pub_isPetFriendly ||
    searchParams.meta_isDrivelahGo ||
    searchParams.pub_instantBooking
  ) {
    data['search_filter_used'].push('General');
  }
  if (searchParams.price) {
    data['search_filter_used'].push('Price');
  }
  if (searchParams.pub_category && searchParams.pub_category.length > 0) {
    data['search_filter_used'].push('Car Category');
  }
  if (searchParams.pub_brandName && searchParams.pub_brandName.length > 0) {
    data['search_filter_used'].push('Brand');
  }
  if (searchParams.pub_keyFeatures && searchParams.pub_keyFeatures.length > 0) {
    data['search_filter_used'].push('Features');
  }
  if (
    searchParams.pub_transmissions &&
    searchParams.pub_transmissions.length > 0
  ) {
    data['search_filter_used'].push('Transmission');
  }
  if (searchParams.pub_fuelType && searchParams.pub_fuelType.length > 0) {
    data['search_filter_used'].push('Fuel Type');
  }
  if (
    searchParams.pub_isPetFriendly &&
    searchParams.pub_isPetFriendly.length > 0
  ) {
    data['search_filter_used'].push('Rules');
  }
  if (searchParams.Keywords) {
    data['search_filter_used'].push('Keywords');
  }
  // data['search_filter_used'] = 'N/A';
  // data['search_numberofpeople_filter'] = 'N/A';
  // data['search_numberofpeople_value'] = 'N/A';

  return data;
};



export const createBookingProperties = (transaction, user = null) => {
  const data = {};

  if (!transaction || !transaction.id) {
    return {};
  }

  const booking = transaction.booking || {};
  const listing = transaction.listing || {};
  const attributes = transaction.attributes || {};
  const customer = transaction.customer || {};
  const lineItems = attributes.lineItems || [];
  const protectedData = attributes.protectedData || {};
  const payinTotal = attributes.payinTotal || {};
  const payoutTotal = attributes.payoutTotal || {};
  const bookingAttributes = booking.attributes || {};

  const start = bookingAttributes.start;
  const end = bookingAttributes.end;
  const displayStart = bookingAttributes.displayStart;
  const displayEnd = bookingAttributes.displayEnd;

  data[BOOKING_PICK_UP_DATE] = computeDateTime(start).date;
  data[BOOKING_PICK_UP_TIME] = computeDateTime(displayStart).time;
  data[BOOKING_DROP_OFF_DATE] = computeDateTime(end).date;
  data[BOOKING_DROP_OFF_TIME] = computeDateTime(displayEnd).time;

  let containsDiscount = lineItems.filter(item => LINE_ITEMS_DISC.includes(item.code));
  let hostEarnings = payoutTotal.amount;

  if (containsDiscount.length > 0) {
    data['booking_discount_type'] = [];
    let totalDiscount = 0;

    containsDiscount.forEach(discount => {
      if (DISCOUNT_TYPES[discount.code]) {
        data['booking_discount_type'].push(DISCOUNT_TYPES[discount.code]);
      } else {
        data['booking_discount_type'].push(discount.code);
      }

      if (discount.lineTotal.amount < 0) {
        totalDiscount += discount.lineTotal.amount * -1;
        if (discount.code !== LINE_ITEM_LONG_TERM_DISCOUNT) {
          hostEarnings += discount.lineTotal.amount * -1;
        }
      } else {
        if (discount.code !== LINE_ITEM_LONG_TERM_DISCOUNT) {
          hostEarnings += discount.lineTotal.amount;
        }
        totalDiscount += discount.lineTotal.amount;
      }

      if (discount.code === LINE_ITEM_CUSTOMER_PROMO && !protectedData.code) {
        data[BOOKING_PROMO_CREDITS] = true;
        data[BOOKING_GUEST_CREDITS_VALUE] = computePrice(discount.lineTotal.amount);
      }
    });

    data['booking_promotion_used'] = protectedData.promotionName;
    data[BOOKING_TOTAL_DISCOUNT] = computePrice(totalDiscount);

    if (protectedData.code) {
      data[BOOKING_PROMO_CODE] = protectedData.code;
    }
  }

  data['booking_host_earnings'] = computePrice(hostEarnings);
  data['revenue'] = computePrice(payinTotal.amount);
  data[BOOKING_TRIP_PRICE] = computePrice(protectedData.commissionObj && protectedData.commissionObj.note && protectedData.commissionObj.note.baseTripPrice);
  data[BOOKING_TRIP_FEES] = computePrice(protectedData.commissionObj && protectedData.commissionObj.commission && protectedData.commissionObj.commission.baseTripFees);
  data['booking_tripfees_guest'] = computePrice(protectedData.commissionObj && protectedData.commissionObj.commission && protectedData.commissionObj.commission.baseTripFees);
  data[BOOKING_YOUNG_DRIVER_FEES] = computePrice(protectedData.commissionObj && protectedData.commissionObj.commission && protectedData.commissionObj.commission.youngDriverFees);
  data[BOOKING_TOTAL_PRICE] = computePrice(payinTotal.amount);
  data[BOOKING_TRIP_DAYS] = protectedData.commissionObj && protectedData.commissionObj.note && protectedData.commissionObj.note.bookingDays;
  data[BOOKING_TRIP_ID] = transaction.id.uuid;
  
  const listingAttributes = listing.attributes || {};
  const listingMetadata = listingAttributes.metadata || {};
  const listingPublicData = listingAttributes.publicData || {};

  const isDrivelahGo = listingMetadata.isDrivelahGo;
  const isInstantBooking = listingPublicData.instantBooking;

  data[BOOKING_INSTANT] = isInstantBooking || protectedData.isInstantBooking;
  data[BOOKING_UNVERIFIED] = protectedData.isUnverified || false;
  data[BOOKING_DL_GO] = isDrivelahGo;
  data[BOOKING_VAS_DELIVERY] = protectedData.isDelivery;
  data[BOOKING_ERROR] = transaction.errorMessage;

  const customerProfile = customer.attributes && customer.attributes.profile || {};
  const customerPublicData = customerProfile.publicData || {};

  data['booking_guest_verification_status'] = customerPublicData.guestIdentityVerificationStatus || 'N/A';
  data['booking_guest_miles_club'] = customerPublicData.premiumUserTier || 'N/A';

  if (user && user.attributes && user.attributes.profile && user.attributes.profile.metadata && user.attributes.profile.metadata.intercomUserStat && user.attributes.profile.metadata.intercomUserStat.success_booking_as_guest > 0) {
    data['booking_returning_user'] = true;
  } else {
    data['booking_returning_user'] = 'false';
  }

  return data;
};

const computePrice = (price) => {
  if (price && price != 0) {
    return (price / 100).toFixed(2);
  } else {
    return price;
  }
};


const computeDateTime = (date) => {
  // console.log("Date came", date, {
  //   date: moment(date).utc().format('MMMM D, YYYY'),
  //   time: moment(date).utc().format('hh:mm:ss a [UTC]'),
  // });
  return {
    date: moment(date).utc().format('MMMM D, YYYY'),
    time: moment(date).utc().format('hh:mm:ss a [UTC]'),
  };
};

export const createTripProperties = (transaction, user = null) => {
  const data = {};
  if (!transaction || !transaction.id) {
    return {};
  }
  const { booking, customer, provider } = transaction;
  const { start, end, displayStart, displayEnd } = booking.attributes;

  data[TRIP_PICKUP_DATE] = computeDateTime(start).date;
  data[TRIP_PICKUP_TIME] = computeDateTime(displayStart).time;
  data[TRIP_DROP_OFF_DATE] = computeDateTime(end).date;
  data[TRIP_DROP_OFF_TIME] = computeDateTime(displayEnd).time;
  data[TRIP_ID] = transaction.id.uuid;

  data[TRIP_DLGO] = transaction.listing && transaction.listing.attributes && transaction.listing.attributes.publicData && transaction.listing.attributes.publicData.isDrivelahGo;
  data[TRIP_NOOFDAYS] = 
    transaction.attributes && transaction.attributes.protectedData && 
    transaction.attributes.protectedData.days && 
    transaction.attributes.protectedData.days.peak + 
    transaction.attributes.protectedData.days.regular;

  data[TRIP_INSTANT_BOOKING] = 
    transaction.listing && transaction.listing.attributes && 
    transaction.listing.attributes.publicData && 
    transaction.listing.attributes.publicData.instantBooking;
  data[TRIP_GUEST_ID] = customer && customer.id && customer.id.uuid;
  data[TRIP_HOST_ID] = provider && provider.id && provider.id.uuid;
  data[TRIP_LISTING_ID] = transaction.listing && transaction.listing.id && transaction.listing.id.uuid;
  data[TRIP_LISTING_NAME] = transaction.listing && transaction.listing.attributes && transaction.listing.attributes.title;

  let pickupPics = transaction.attributes && transaction.attributes.transitions && transaction.attributes.transitions.filter((t) => ALL_KINDS_OF_PICKUP.includes(t.transition));
  let dropOffPics = transaction.attributes && transaction.attributes.transitions && transaction.attributes.transitions.filter((t) => ALL_KINDS_OF_DROPOFF.includes(t.transition));
  
  if (pickupPics && pickupPics.length > 0) {
    data[TRIP_PICKUP_SENT] = true;
    let tempTime = computeDateTime(pickupPics[0].createdAt);
    data['trip_pickup_sent_at'] = `${tempTime.date} ${tempTime.time}`;
  } else {
    data[TRIP_PICKUP_SENT] = 'false';
  }
  if (dropOffPics && dropOffPics.length > 0) {
    data[TRIP_DROPOFF_SENT] = true;
    let tempTime = computeDateTime(dropOffPics[0].createdAt);
    data['trip_dropoff_sent_at'] = `${tempTime.date} ${tempTime.time}`;
  } else {
    data[TRIP_DROPOFF_SENT] = 'false';
  }

  data['trip_guest_verification_status'] = 
    customer && customer.attributes && customer.attributes.profile && customer.attributes.profile.publicData && customer.attributes.profile.publicData.guestIdentityVerificationStatus;
  
  if (user && user.attributes && user.attributes.profile && user.attributes.profile.metadata && user.attributes.profile.metadata.intercomUserStat && user.attributes.profile.metadata.intercomUserStat.success_booking_as_guest > 0) {
    data['trip_returning_user'] = true;
  } else {
    data['trip_returning_user'] = 'false';
  }
  data['trip_guest_miles_club'] = 
    customer && customer.attributes && customer.attributes.profile && customer.attributes.profile.publicData && customer.attributes.profile.publicData.premiumUserTier;
  data['trip_totalprice'] = computePrice(transaction.attributes && transaction.attributes.payinTotal && transaction.attributes.payinTotal.amount);
  data[TRIP_VAS_DELIVERY] = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.isDelivery;
  data[TRIP_VAS_EXCESS] = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.isExcessReduction;
  data[TRIP_VAS_EXCESS_2] = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.isExcessReduction2;
  data[TRIP_VAS_FUEL] = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.isFuelInclusion;
  data['trip_vas_mileage'] = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.isMileagePackageIncluded;
  data['trip_vas_mileage_selected'] = transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.selectedMileage;

  return data;
};



export const bucketEventMapping = (eventProperties, props = {}) => {
  try {
    const { 
      ui,
      signup,
      guest,
      search,
      host,
      enquiry,
      booking,
      vas,
      listing,
      trip,
      currentUser,
     } = props;
    // console.log("Current Props passed", props);
  
    let uiProps = createUIAndDateTimeProperties(
      ui && ui.page ? ui.page : '',
      ui && ui.button ? ui.button : ''
    );
  
    let signupProps = signup ? createSignupProperties(signup) : {};
    let guestProps = guest ? createGuestProperties(guest) : {};
    let searchProps = search ? createSearchProperties({searchParams:search, source: ui && ui.page ? ui.page : '', bookingTx: trip}) : {};
    let hostProps = host ? createHostProperties(host) : {};
    let listingProps = listing
      ? createListingProperties(listing, ui && ui.source ? ui.source : ui && ui.page ? ui.page : '')
      : {};
    let enquiryProps = enquiry ? createBookingProperties(enquiry, guest) : {};
    let bookingProps = booking ? createBookingProperties(booking, guest) : {};
    let tripProps = trip ? createTripProperties(trip, guest || currentUser) : {};
    let vasProps = vas ? createVASProperties(vas, ui && ui.page ? ui.page : '') : {};
  
    const analyticsData = {
      ...uiProps,
      ...signupProps,
      ...guestProps,
      ...searchProps,
      ...hostProps,
      ...enquiryProps,
      ...bookingProps,
      ...vasProps,
      ...listingProps,
      ...tripProps,
    };
  
    // console.log("final mapped data", analyticsData);
  
    // Create a result object containing only the properties from eventProperties
    const result = {};
    eventProperties.forEach(property => {
      let propName = formatName(property.Property_Name.replace(/ /g, '_'));
      // console.log("Property data", property, property.Property_Name);
      if (propName in analyticsData) {
        result[propName] = analyticsData[propName];
      }
    });
  
    // console.log("Mapping Event Properties", eventProperties);
    // console.log(result);
  
    return result;
  } catch (error) {
    console.log("errro for making event properties", error)
    return false;
  }
};


export const triggerAnalyticsEvent = async ({ event_id, eventData, props,  user, isBackendApiCall = false, userId = null, isSessionSent=true }) => {
  // Find the event by event_trigger_id
  try {
    // console.log('Event trigger from amplitude', { event_id, eventData, props,  user, isBackendApiCall, userId  })
    const event = eventData && eventData.eventDetails.find(event => event.event_trigger_id === event_id);
  
    if (!event) {
      console.log(`Event with event_trigger_id '${event_id}' not found.`);
      return;
    }
  
    // Collect corresponding event properties
    const correspondingProperties = eventData.eventProperties.filter(property => event[property.Bucket] === 'x');
  
    // Create the GTM event properties mapping
    // { guest, 
    // currentListing, 
    // signup, 
    // ui = {}, 
    // host, 
    // search }
    const eventSendData = bucketEventMapping(correspondingProperties, props);
  
    // console.log("eventSendData data for evnet", event_id, eventSendData)
  
    // Prepare final event data
    if(!eventSendData) {
      console.log("No segment data found for evnet", event_id);
      return;
    }
    const finalEventData = {
      event: event.Event_Name,
      properties: { ...eventSendData }
    };
  
    // Log the event
    // console.log("Sending the amplitude data", finalEventData);
    // console.log("Final event", event_id, isBackendApiCall, userId);
  
    if (isBackendApiCall) {
      // Call the backend API if the flag is set
      try {
        // If userId is not provided, use the userId from local storage
        // if (!userId && isSessionSent) {
        //   userId = getUserId();
        // }
        // console.log("total event data", finalEventData.event, finalEventData.properties, userId);
        const eventWebProperties = {
          ...finalEventData.properties,
            event_source: 'Web'
        }
        await logEventViaApi({
          eventName: finalEventData.event, 
          eventProperties:eventWebProperties,
          userId,
          isSessionSent
        }
        );
        const revenueCheckData  = {
          event: {
            event_trigger_id: event_id,
            properties: {
              ...finalEventData.properties,
            } 
          }
        }
        checkForRevenue(revenueCheckData);
      } catch (error) {
        console.error('Error logging event via API:', error);
      }
    } else {
      if(!userId) {
        const cachedUser = getCurrentUser();
        // console.log("Cached user", cachedUser);
        userId = (cachedUser && cachedUser.id.uuid)
      }

      // if(!userId) {
      //   userId = getUserId();
      // }
      // Log event locally
      logEvent({eventName: finalEventData.event, user, eventProperties: finalEventData.properties, userId});
      const revenueCheckData  = {
        event: {
          event_trigger_id: event_id,
          properties: finalEventData.properties
        }
      }
      checkForRevenue(revenueCheckData);
    }

  }
  catch(error) {
    console.log('Error logging event via analytics:', event_id, error);
  }
};
